import { Controller } from 'stimulus';
import * as bootstrap from 'bootstrap';
import Rails from "@rails/ujs";


export default class extends Controller {
  static values = {
    showurl: String,
    editurl: String,
    confirmurl: String,
    nextdayurl: String,
    id: Number
  }

  connect() {
    console.log(this.idValue);
  }

  show(event) {
    event.preventDefault();
    let card = document.getElementsByClassName('modal-content')[0]
    
    fetch(this.showurlValue).then((r) => r.text()).then((html) => {
      card.innerHTML = html;
      let myModal = new bootstrap.Modal(document.getElementById('myModal2'), {
        keyboard: true
      })
      myModal.show();
    });
  }

  edit(event) {
    event.preventDefault();
    let card = document.getElementsByClassName('modal-content')[0]

    fetch(this.editurlValue).then((r) => r.text()).then((html) => {
      card.innerHTML = html;
      let myModal = new bootstrap.Modal(document.getElementById('myModal2'), {
        keyboard: true
      })
    });
  }

  confirmDay(event) {
    event.preventDefault();
    let idValue = this.idValue;
    Rails.ajax({
      type: "POST",
      url: this.confirmurlValue,
      data: '',
      dataType: 'script',
      success: function(data) {
        var myModalEl = document.getElementById('myModal2')
        var modal = bootstrap.Modal.getInstance(myModalEl)
        modal.hide();
        document.getElementById('order_' + idValue).remove()
      }
    });
  }

  updateSuccess(event) {
    let [data, status, xhr] = event.detail;
    let card = document.getElementsByClassName('modal-content')[0]
    card.innerHTML = xhr.response;
    if(document.getElementById('overtime-orders-table') || document.getElementById(waitting-orders-table)) {
      fetch('/orders/' + this.idValue + '/status').then((r) => r.text()).then((data) => {
        console.log(status)
        if(data.task_status !== null) {
          document.getElementById('order_' + this.idValue).remove();
        }
      })
    }
  }

  updateFailure(event) {
    console.log('failure')
    let [data, status, xhr] = event.detail;
    console.log(xhr.response)
    let card = document.getElementsByClassName('modal-content')[0]
    card.innerHTML = xhr.response;
  }
}
