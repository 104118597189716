import { Controller } from 'stimulus';
import * as bootstrap from 'bootstrap';
import Rails from "@rails/ujs";


export default class extends Controller {
  static values = {
    editurl: String,
    identifier: String,
    id: Number,
    newurl: String,
    deleteurl: String
  }

  connect() {
    console.log('working')
  }

  edit(event) {
  }

  delete(event) {
    event.preventDefault();
    console.log(this.idValue)
    
    Rails.ajax({
      type: "delete",
      method: 'delete',
      url: "/pickup_rules/" + this.idValue,
      data: '',
      dataType: 'json',
      success: function(_data) {
        event.target.parentNode.parentNode.remove();
      }
    });
  }

  createSuccess(event) {
    let [data, status, xhr] = event.detail;
    let table = document.getElementById("exeptions-table");
    let row = table.insertRow(1);
    row.innerHTML = xhr.response;
    var myModalEl = document.getElementById('myModal2')
    var modal = bootstrap.Modal.getInstance(myModalEl)
    modal.hide();
  }

  updateSuccess() {

  }

  updateFailure() {
    console.log('failure')
    let [data, status, xhr] = event.detail;
    console.log(xhr.response)
    let card = document.getElementsByClassName('modal-content')[0]
    card.innerHTML = xhr.response;
  }

  change(event) {
    Rails.ajax({
      type: "PUT",
      url: "/pickup_rules/" + this.idValue,
      data: new URLSearchParams({ enabled: event.target.checked }).toString(),
      dataType: 'json',
      success: function(data) {
        event.target.checked = data.enabled
      }
    });
  }

  create(event) {
    event.preventDefault();
    let card = document.getElementsByClassName('modal-content')[0]

    fetch(this.newurlValue).then((r) => r.text()).then((html) => {
      card.innerHTML = html;
      let myModal = new bootstrap.Modal(document.getElementById('myModal2'), {
        keyboard: true
      })
      myModal.show();
    });
  }

  handleChange(event) {
    console.log('change')
    let form = document.getElementById('pickups-import-form');
    form.submit();
    Rails.fire(form, 'submit');
  }
}