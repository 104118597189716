import { Controller } from 'stimulus';
import * as bootstrap from 'bootstrap';


export default class extends Controller {
  static values = {
    editurl: String,
    newurl: String,
    id: Number
  }

  connect() {
  }

  edit(event) {
    console.log(this.editurlValue)
    event.preventDefault();
    let card = document.getElementsByClassName('modal-content')[0]

    fetch(this.editurlValue).then((r) => r.text()).then((html) => {
      card.innerHTML = html;
      let myModal = new bootstrap.Modal(document.getElementById('myModal2'), {
        keyboard: true
      })
      myModal.show();
    });
  }

  create(event) {
    event.preventDefault();
    let card = document.getElementsByClassName('modal-content')[0]

    fetch(this.newurlValue).then((r) => r.text()).then((html) => {
      card.innerHTML = html;
      let myModal = new bootstrap.Modal(document.getElementById('myModal2'), {
        keyboard: true
      })
      myModal.show();
    });
  }

  createSuccess(event) {
    let [data, status, xhr] = event.detail;
    let table = document.getElementsByClassName("table")[0];
    let row = table.insertRow(1);
    row.innerHTML = xhr.response;
    var myModalEl = document.getElementById('myModal2')
    var modal = bootstrap.Modal.getInstance(myModalEl)
    modal.hide();
  }


  updateSuccess(event) {
    let [data, status, xhr] = event.detail;
    console.log(data)
    console.log(xhr)
    let table_row = document.getElementById("user_" + this.idValue)
    table_row.innerHTML = xhr.response;
    var myModalEl = document.getElementById('myModal2')
    var modal = bootstrap.Modal.getInstance(myModalEl)
    modal.hide();
  }

  updateFailure(event) {
    console.log('failure')
    let [data, status, xhr] = event.detail;
    console.log(xhr.response)
    let card = document.getElementsByClassName('modal-content')[0]
    card.innerHTML = xhr.response;
  }
}